import React from 'react';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
  UseFormRegisterReturn,
} from 'react-hook-form';

import { FormField } from './FormField';
import { Input, InputProps } from './Input';

type Props<T extends FieldValues> = Omit<
  InputProps,
  'value' | keyof UseFormRegisterReturn
> & {
  name: FieldPath<T>;
  label?: string;
  required?: boolean | string;
  register: UseFormRegister<T>;
  options?: RegisterOptions<T>;
  hasError?: boolean;
  errorMessage?: string;
};

export function InputField<T extends FieldValues>({
  name,
  label,
  register,
  required,
  options,
  hasError,
  errorMessage,
  ...props
}: Props<T>) {
  return (
    <FormField
      name={name}
      label={label}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
    >
      <Input {...props} {...register(name, { ...options, required })} />
    </FormField>
  );
}
